<template>
  <v-bottom-sheet
    :value="show"
    @input="!$event && $emit('click:reset')"
    hide-overlay
    fullscreen
    content-class="position-relative"
    eager
  >
    <v-app-bar
      absolute
      :color="isShowNavbar ? '' : 'transparent'"
      :elevation="isShowNavbar ? 2 : 0"
      class="px-2"
    >
      <v-btn
        @click="!showBack ? $emit('click:reset') : $emit('click:back')"
        icon
        :dark="!isShowNavbar"
        depressed
        outlined
        small
      >
        <v-icon small>mdi-arrow-down</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn
        @click="$emit('click:reset')"
        icon
        :dark="!isShowNavbar"
        depressed
        outlined
        small
      >
        <v-icon small>mdi-close </v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-card
      v-scroll.self="onScroll"
      @click="openFullScreen"
      :style="{ height: '40vh' }"
      :class="{ 'overflow-auto': isFullscreen }"
      :tile="isFullscreen"
      :ripple="false"
      ref="card"
    >
      <slot :isFullscreen="isFullscreen" />
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { Swipe } from "@service/model/Swipe";
export default {
  props: { show: Boolean, showBack: Boolean },
  data: () => ({
    isFullscreen: true,
    scrollPosition: null,
    bindedTouchStart: null,
    bindedTouchMove: null,
    swipe: null,
  }),
  computed: {
    c_show: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    isShowNavbar() {
      return this.scrollPosition > 50;
    },
  },
  watch: {},
  mounted() {
    // this.$nextTick(() => {
    //   this.onSetSwipe();
    // });
  },
  beforeDestroy() {
    if (this.swipe) {
      this.swipe.destroy();
    }
  },
  methods: {
    onSetSwipe() {
      if (this.swipe || !this.$refs.card) {
        return;
      }
      this.swipe = new Swipe(this.$refs.card.$el);
      this.swipe.onUp((e) => {
        if (!this.isFullscreen && this.c_show) {
          this.isFullscreen = true;
        }
      });
      this.swipe.onDown((e) => {
        if (!this.isShowNavbar && this.c_show) {
          this.$emit("click:back");
        }
      });
    },
    onScroll(e) {
      this.scrollPosition = e.target.scrollTop;
    },
    toggleFullscreen(e) {
      e.stopPropagation();
      e.preventDefault();
      this.isFullscreen = !this.isFullscreen;
      if (!this.isFullscreen) {
        this.$emit("click:back");
      }
    },
    openFullScreen(e) {
      e.stopPropagation();
      e.preventDefault();
      if (!this.isFullscreen) this.isFullscreen = true;
    },
  },
};
</script>

<style scoped></style>
