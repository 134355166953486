<template>
  <v-card flat tile class="full-height d-flex flex-column overflow-auto">
    <FileViewContainer :items="images" v-if="images.length > 0">
      <template #default="{ viewFile }">
        <v-carousel height="150px" hide-delimiters>
          <v-carousel-item
            v-for="item in images"
            :key="item.id"
            @click="viewFile(item, 'image')"
          >
            <v-sheet height="100%" tile>
              <v-img :src="item.link" height="100%" width="100%"></v-img>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </template>
    </FileViewContainer>

    <v-img
      v-else
      :src="value.background || '/images/default-picture-1.png'"
      class="image-search-container flex-grow-0 flex-shrink-1"
      min-height="150px"
    ></v-img>
    <SearchPlaceResult
      class="flex-grow-1"
      style="min-height: 150px"
      :value="value"
      :loading.sync="c_loading"
      hiddenImages
    />
  </v-card>
</template>

<script>
import SearchPlaceResult from "./SearchPlaceResult.vue";
export default {
  components: {
    SearchPlaceResult,
    FileViewContainer: () => import("@components/File/FileViewContainer.vue"),
  },
  props: {
    value: { type: Object },
    loading: Boolean,
  },
  data: () => ({}),
  computed: {
    images() {
      return (this.value.images || []).map((x) => ({ ...x, path: x.link }));
    },
    c_loading: {
      get() {
        return this.loading;
      },
      set(value) {
        this.$emit("update:loading", value);
      },
    },
  },
  methods: {},
};
</script>

<style scoped></style>
