export class Swipe {
  constructor(element) {
    this.xDown = null;
    this.yDown = null;
    this.element =
      typeof element === "string" ? document.querySelector(element) : element;

    this.element.addEventListener(
      "touchstart",
      this.onTouchStart.bind(this),
      false
    );
    this.element.addEventListener(
      "touchmove",
      this.handleTouchMove.bind(this),
      false
    );
    this._onLeft = () => {};
    this._onRight = () => {};
    this._onUp = () => {};
    this._onDown = () => {};
  }
  onTouchStart(e) {
    this.xDown = e.touches[0].clientX;
    this.yDown = e.touches[0].clientY;
  }

  onLeft(callback) {
    this._onLeft = callback;

    return this;
  }

  onRight(callback) {
    this._onRight = callback;

    return this;
  }

  onUp(callback) {
    this._onUp = callback;

    return this;
  }

  onDown(callback) {
    this._onDown = callback;

    return this;
  }

  handleTouchMove(evt) {
    if (!this.xDown || !this.yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    this.xDiff = this.xDown - xUp;
    this.yDiff = this.yDown - yUp;

    if (Math.abs(this.xDiff) > Math.abs(this.yDiff)) {
      // Most significant.
      if (this.xDiff > 0) {
        this._onLeft();
      } else {
        this._onRight();
      }
    } else {
      if (this.yDiff > 0) {
        this._onUp();
      } else {
        this._onDown();
      }
    }

    // Reset values.
    this.xDown = null;
    this.yDown = null;
  }
  destroy() {
    this.element.removeEventListener("touchstart", this.onTouchStart, false);
    this.element.removeEventListener("touchmove", this.onTouchStart, false);
  }
}
